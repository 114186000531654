import React, { useEffect } from 'react';
import {
    Box,
    Button,
    Container,
    Flashbar,
    Grid,
    Link,
    Modal,
    Multiselect,
    Popover,
    SelectProps,
    SpaceBetween,
    StatusIndicator,
} from '@amzn/awsui-components-react';
import { toggleSelectSite } from '../stores/slices/userSlice';
import { useTranslation } from 'react-i18next';
import { FlashbarProps } from '@amzn/awsui-components-react/polaris/flashbar';
import { useAppDispatch, useAppSelector } from '../stores/slices/hooks';
import { CONSTANTS } from 'src/resources/constants';
import { getDevicesForSites } from 'src/components/actions';
import { setSelectedSites } from 'src/stores/slices/userPrefsSlice';

const SelectSite = () => {
    const { t } = useTranslation();
    const [flashbarContent, setFlashbarContent] = React.useState([] as FlashbarProps.MessageDefinition[]);

    const allowedSitesFromState = useAppSelector((state) => state.deviceState.allowedSites);
    const selectedSitesFromState = useAppSelector((state) => state.userPrefsState.selectedSites);
    const showSelectSite = useAppSelector((state) => state.userState.showSelectSite);

    const dispatch = useAppDispatch();

    function continueClick() {
        if (selectedSitesFromState.length > 0) {
            dispatch(getDevicesForSites(selectedSitesFromState));
            dispatch(toggleSelectSite());
        }
    }

    function exitWarn() {
        if (selectedSitesFromState.length <= 0) {
            setFlashbarContent([
                {
                    type: 'info',
                    dismissible: true,
                    dismissLabel: t('Dismiss message'),
                    onDismiss: () => setFlashbarContent([]),
                    content: <>{t('Please select site(s)')}</>,
                },
            ]);
        } else {
            dispatch(toggleSelectSite());
        }
    }

    useEffect(() => {
        if (selectedSitesFromState.length >= 0) {
            continueClick();
        }
        return () => {};
    }, []);

    return (
        <>
            <Modal
                id={'selectSiteModal'}
                visible={showSelectSite}
                size='large'
                header={t('Select Site(s)')}
                onDismiss={() => exitWarn()}
                footer={
                    <Grid
                        gridDefinition={[
                            { colspan: 4 },
                            { colspan: 1 },
                            { colspan: 3 },
                            { colspan: 1 },
                            { colspan: 3 },
                        ]}
                    >
                        <div></div>
                        <div />
                        <div>
                            <Link href={CONSTANTS.HELP_LINK} external target={'_blank'}>
                                Need Assistance?
                            </Link>
                        </div>
                        <div />
                        <div>
                            <Box float={'right'}>
                                <Popover
                                    content={
                                        <StatusIndicator type='warning'>
                                            {t('Please select a site to continue')}
                                        </StatusIndicator>
                                    }
                                    position='top'
                                    size='large'
                                    triggerType='custom'
                                >
                                    <Button
                                        id={'selectSiteContinueButton'}
                                        onClick={continueClick}
                                        variant='primary'
                                        disabled={selectedSitesFromState.length <= 0}
                                    >
                                        Continue
                                    </Button>
                                </Popover>
                            </Box>
                        </div>
                    </Grid>
                }
            >
                <Container>
                    <SpaceBetween size='xxl' direction='vertical'>
                        <Flashbar items={flashbarContent} />

                        <Multiselect
                            selectedOptions={
                                selectedSitesFromState.map((s) => {
                                    return {
                                        value: s,
                                        label: s,
                                    } as SelectProps.Option;
                                }) as SelectProps.Options
                            }
                            onChange={({ detail }) => {
                                dispatch(
                                    setSelectedSites(
                                        detail.selectedOptions.map((siteOption) => {
                                            return siteOption.label ?? '';
                                        })
                                    )
                                );
                            }}
                            filteringType='auto'
                            placeholder={t('Select Site(s)')}
                            empty={t('No Sites Assigned Please Contact Support')} //TODO: Show help link
                            options={
                                allowedSitesFromState.map((allowedSiteCode) => {
                                    return {
                                        value: allowedSiteCode,
                                        label: allowedSiteCode,
                                    } as SelectProps.Option;
                                }) as SelectProps.Options
                            }
                            /*expandToViewport={true}
                        keepOpen={false}*/
                            virtualScroll={true}
                            loadingText={'Loading your sites, please wait'}
                            id={'selectSites'}
                            selectedAriaLabel='Selected'
                            //onLoadItems={(e) => log('OnLoad ' + JSON.stringify(e))}
                        />
                    </SpaceBetween>
                </Container>
            </Modal>
        </>
    );
};

export default SelectSite;
