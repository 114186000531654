export const SPLIT_PANEL_I18NSTRINGS = {
    preferencesTitle: 'Split panel preferences',
    preferencesPositionLabel: 'Split panel position',
    preferencesPositionDescription: 'Choose the default split panel position for the service.',
    preferencesPositionSide: 'Side',
    preferencesPositionBottom: 'Bottom',
    preferencesConfirm: 'Confirm',
    preferencesCancel: 'Cancel',
    closeButtonAriaLabel: 'Close panel',
    openButtonAriaLabel: 'Open panel',
    resizeHandleAriaLabel: 'Resize split panel',
};
export const CONSTANTS = {
    WIKI_LINK: 'https://w.amazon.com/bin/view/AccSys/inner/FAR/DeviceRenaming/',
    HELP_LINK: 'https://w.amazon.com/bin/view/AccSys/inner/FAR/DeviceRenaming/',
    GUARDIAN_HUB: 'https://guardian.gso.amazon.dev/',
    REPORT_BUG: 'https://t.corp.amazon.com/create/templates/394d2264-f504-4f42-ade5-262a7f2adda6',
    FEATURE_REQUEST: 'https://issues.amazon.com/issues/create?template=655ae547-bbc7-4274-9bbb-d25ee9fd0920',
};
