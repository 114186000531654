import { TreeDevice } from 'src/types';
import { ThunkAction } from '@reduxjs/toolkit';
import {
    DevicesSliceInterface,
    setCheckedChild,
    setCheckedParent,
    setCheckedSubChild,
    setChildDeviceName,
    setChildErrorText,
    setChildExpanded,
    setChildLoading,
    setParentDeviceName,
    setParentErrorText,
    setParentExpanded,
    setParentLoading,
    setSubChildDeviceName,
    setSubChildErrorText,
    setSubChildLoading,
} from 'src/stores/slices/devicesSlice';
import { TAppState, TDispatch, TGetState } from 'src/stores/slices/store';
import { checkDeviceNameValid, checkDeviceSiteCodeMatchesParent, getNameWithoutPrefix, getParentSiteCode, isDeviceChild, isDeviceParent, log } from 'src/utils/helpers';
import { API, graphqlOperation } from '@aws-amplify/api';
import { renameDevices } from 'src/graphql/mutations';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import { DeviceInput, RenameDevicesMutation } from 'src/API';

interface DispatchProps {
    login: (username: string, password: string) => void;
}

interface IState {
    deviceState: DevicesSliceInterface;
}

// type TPromise = Promise<{ error: string } | Error>; // Return type instead of void
export const setChecked =
    (props: {
        device: TreeDevice;
        checked: boolean;
    }): ThunkAction<
        void, // thunk return type
        TAppState, // state type
        any, // extra argument, (not used)
        { type: 'SET_CHECKED'; payload: { device: TreeDevice; checked: boolean } } // action type
    > =>
    (dispatch: TDispatch, getState: TGetState): void => {
        // use getState
        // dispatch start saveEntry action
        // make async call
        let toCheckExpand: TreeDevice[] = [];
        if (props.device.child_device_id == 0 && props.device.subchild_device_id == 0) {
            dispatch(setCheckedParent({ device: props.device, checked: props.checked }));
            if (props.checked) {
                dispatch(setParentExpanded({ device: props.device, expanded: true }));
            }
            for (const cDevice of getState().deviceState.childDevices) {
                if (cDevice.parent_device_id == props.device.parent_device_id) {
                    dispatch(setCheckedChild({ device: cDevice, checked: props.checked }));
                    // Expand children if parent was selected
                    if (props.checked) {
                        dispatch(setChildExpanded({ device: cDevice, expanded: true }));
                    }
                }
            }
            for (const scDevice of getState().deviceState.subchildDevices) {
                if (props.device.parent_device_id == scDevice.parent_device_id) {
                    dispatch(setCheckedSubChild({ device: scDevice, checked: props.checked }));
                }
            }
            // dispatch(setSubChildrenChecked({ device: props.device, checked: props.checked }));
        } else if (props.device.child_device_id != 0 && props.device.subchild_device_id == 0) {
            dispatch(setCheckedChild({ device: props.device, checked: props.checked }));
            if (props.checked) {
                dispatch(setChildExpanded({ device: props.device, expanded: true }));
            }
            // dispatch(setSubChildrenChecked({ device: props.device, checked: props.checked }));
            for (const scDevice of getState().deviceState.subchildDevices) {
                if (
                    props.device.parent_device_id == scDevice.parent_device_id &&
                    props.device.child_device_id == scDevice.child_device_id
                ) {
                    dispatch(setCheckedSubChild({ device: scDevice, checked: props.checked }));
                }
            }
        } else {
            dispatch(setCheckedSubChild({ device: props.device, checked: props.checked }));
        }
        return;
    };

function isChecked(device: TreeDevice) {
    if (device.checked) {
        return true;
    }
}

export const addTextToSelected =
    (props: {
        toAdd: string;
    }): ThunkAction<
        void, // thunk return type
        TAppState, // state type
        any, // extra argument, (not used)
        { type: 'ADD_TEXT_TO_SELECTED'; payload: { toAdd: string } } // action type
    > =>
    (dispatch: TDispatch, getState: TGetState): void => {
        const parentDevices = getState().deviceState.parentDevices.filter(isChecked);
        const childDevices = getState().deviceState.childDevices.filter(isChecked);
        const subchildDevices = getState().deviceState.subchildDevices.filter(isChecked);

        for (const device of parentDevices.concat(childDevices).concat(subchildDevices)) {
            if (!device.device_name.includes(`${props.toAdd}`)
                && !(device.DeviceSource === 'keep' && props.toAdd === 'keep_')) {
                dispatch(deviceNameChanged({
                    device,
                    newName: `${props.toAdd}${getNameWithoutPrefix(device.device_name)}`
                }));
            }
        }
    };

export const removeTextFromSelected =
    (props: {
        toRemove: string;
    }): ThunkAction<
        void, // thunk return type
        TAppState, // state type
        any, // extra argument, (not used)
        { type: 'REMOVE_TEXT_FROM_SELECTED'; payload: { toRemove: string } } // action type
    > =>
    (dispatch: TDispatch, getState: TGetState): void => {
        const parentDevices = getState().deviceState.parentDevices.filter(isChecked);
        const childDevices = getState().deviceState.childDevices.filter(isChecked);
        const subchildDevices = getState().deviceState.subchildDevices.filter(isChecked);

        for (const device of parentDevices.concat(childDevices).concat(subchildDevices)) {
            if (device.device_name.includes(`${props.toRemove}`)) {
                const newName = device.device_name.replace(`${props.toRemove}`, '');
                dispatch(deviceNameChanged({ device, newName }));
            }
        }
    };

export const setDeviceLoading =
    (props: {
        device: TreeDevice;
        loading: boolean;
    }): ThunkAction<
        void, // thunk return type
        TAppState, // state type
        any, // extra argument, (not used)
        { type: 'CHANGE_DEVICE_NAME'; payload: { device: TreeDevice; loading: boolean } } // action type
    > =>
    async (dispatch: TDispatch, getState: TGetState): Promise<void> => {
        if (props.device.child_device_id == 0 && props.device.subchild_device_id == 0) {
            dispatch(setParentLoading({ device: props.device, loading: props.loading }));
        } else if (props.device.child_device_id != 0 && props.device.subchild_device_id == 0) {
            dispatch(setChildLoading({ device: props.device, loading: props.loading }));
        } else {
            dispatch(setSubChildLoading({ device: props.device, loading: props.loading }));
        }
    };

export const changeNameById =
    (props: {
        parentId: number;
        childId: number;
        subchildId: number;
        newName: string;
    }): ThunkAction<
        void, // thunk return type
        TAppState, // state type
        any, // extra argument, (not used)
        { type: 'CHANGE_DEVICE_NAME'; payload: { device: TreeDevice; newName: boolean } } // action type
    > =>
    async (dispatch: TDispatch, getState: TGetState): Promise<void> => {
        log(`Device by id ${props.newName}`);
        if (props.childId == 0 && props.subchildId == 0) {
            // panel
            getState().deviceState.parentDevices.forEach((device) => {
                if (device.parent_device_id == props.parentId) {
                    dispatch(deviceNameChanged({ device: device, newName: props.newName }));
                    log(
                        `Changing ${device.parent_device_id}_${device.child_device_id}_${device.subchild_device_id}: ${device.device_name} -> ${props.newName}`,
                    );
                }
            });
        } else if (props.subchildId == 0) {
            // child
            getState().deviceState.childDevices.forEach((device) => {
                if (device.parent_device_id == props.parentId && device.child_device_id == props.childId) {
                    dispatch(deviceNameChanged({ device: device, newName: props.newName }));

                    log(
                        `Changing ${device.parent_device_id}_${device.child_device_id}_${device.subchild_device_id}: ${device.device_name} -> ${props.newName}`,
                    );
                }
            });
        } else {
            // subchild
            getState().deviceState.subchildDevices.forEach((device) => {
                if (
                    device.parent_device_id == props.parentId &&
                    device.child_device_id == props.childId &&
                    device.subchild_device_id == props.subchildId
                ) {
                    dispatch(deviceNameChanged({ device: device, newName: props.newName }));

                    log(
                        `Changing ${device.parent_device_id}_${device.child_device_id}_${device.subchild_device_id}: ${device.device_name} -> ${props.newName}`,
                    );
                }
            });
        }
    };

export const deviceNameChanged =
    (props: {
        device: TreeDevice;
        newName: string;
        isParentRename?: boolean;
    }): ThunkAction<
        void, // thunk return type
        TAppState, // state type
        any, // extra argument, (not used)
        { type: 'CHANGE_DEVICE_NAME'; payload: { device: TreeDevice; newName: string } } // action type
    > =>
    async (dispatch: TDispatch, getState: TGetState): Promise<void> => {
        log(`Device name to appsync ${props.newName}`);
        let errorText = '';
        dispatch(setDeviceLoading({ device: props.device, loading: true }));

        if (props.device.device_name !== props.newName) {
            if (props.device.device_type_name_special === 'lidar') {
                const nameWithoutPrefix = getNameWithoutPrefix(props.device.device_name);
                const newNameWithoutPrefix = getNameWithoutPrefix(props.newName);
                if (nameWithoutPrefix !== newNameWithoutPrefix) {
                    dispatch(setDeviceLoading({ device: props.device, loading: false }));
                    dispatch(getErrorMethod(props.device, 'Only changing prefix is allowed for LIDAR devices'));
                    return;
                }
            }

            if (checkDupes(props.device, props.newName, getState())) {
                dispatch(setDeviceLoading({ device: props.device, loading: false }));
                dispatch(getErrorMethod(props.device, 'Duplicate name found'));
                return;
            }

            log('No duplicates found');
            const newDevice: DeviceInput = {
                DeviceSource: props.device.DeviceSource,
                SiteCode: props.device.SiteCode,
                child_device_id: props.device.child_device_id,
                child_device_name: props.device.child_device_name,
                device_name: props.newName,
                device_type_id: props.device.device_type_id,
                device_type_name: props.device.device_type_name,
                device_type_name_special: props.device.device_type_name_special,
                parent_device_id: props.device.parent_device_id,
                parent_device_name: props.device.parent_device_name,
                region_id: props.device.region_id,
                subchild_device_id: props.device.subchild_device_id,
                subchild_device_name: props.device.subchild_device_name,
                device_key: props.device.device_key as string,
                device_href: props.device.device_href,
                communication_address: props.device.communication_address,
                communication_port: props.device.communication_port
            };

            const deviceForChecks = newDevice as unknown as TreeDevice;
            const parentDeviceName = getState().deviceState.parentDevices.find(device =>
                deviceForChecks.parent_device_id === device.parent_device_id)?.device_name!;
            if (!checkDeviceNameValid(deviceForChecks, parentDeviceName)) {
                errorText = 'Device name is invalid';
            } else if (!props.isParentRename && !isDeviceParent(deviceForChecks) &&
                !checkDeviceSiteCodeMatchesParent(deviceForChecks.device_name, parentDeviceName)) {
                errorText = 'Site code does not match parent site code.';
            } else {
                let appsyncResponse: GraphQLResult<RenameDevicesMutation> = {};
                try {
                    appsyncResponse = await (API.graphql(
                        graphqlOperation(renameDevices, { devices: [newDevice] }),
                    ) as Promise<GraphQLResult<RenameDevicesMutation>>);
                } catch (e) {
                    log('Error with renaming device', false, { error: e });
                }
                if (!appsyncResponse || !appsyncResponse.data?.renameDevices) {
                    // Set error text if response is not true
                    errorText = 'Server error, please try again later';
                    log(`Appsync server error ${props.device.deviceKey}`, true);
                } else {
                    if (props.device.child_device_id == 0 && props.device.subchild_device_id == 0) {
                        dispatch(setParentDeviceName({ device: props.device, newName: props.newName }));
                    } else if (props.device.child_device_id != 0 && props.device.subchild_device_id == 0) {
                        dispatch(setChildDeviceName({ device: props.device, newName: props.newName }));
                    } else {
                        dispatch(setSubChildDeviceName({ device: props.device, newName: props.newName }));
                    }
                }
            }
        } else {
            log('No change needed');
            errorText = '';
        }
        // Update error text field or remove it if empty
        dispatch(getErrorMethod(props.device, errorText));

        dispatch(setDeviceLoading({ device: props.device, loading: false }));
    };

const getErrorMethod = (device: TreeDevice, errorText: string) => {
    const payload = { device, errorText };
  if (isDeviceParent(device)) {
    return setParentErrorText(payload);
  } else if (isDeviceChild(device)) {
    return setChildErrorText(payload);
  } else {
    return setSubChildErrorText(payload);
  }
}

const checkDupes = (device: TreeDevice, newName: string, state: TAppState): boolean => {
    const devices = getDeviceHierarchyLevel(device, state);
    const duplicate = devices.find(curDevice => 
        curDevice.DeviceSource === device.DeviceSource &&
        curDevice.deviceKey !== device.deviceKey &&
        curDevice.device_type_name_special === device.device_type_name_special && 
        curDevice.device_name === newName
    );
    return duplicate !== undefined;
}

const getDeviceHierarchyLevel = (device: TreeDevice, state: TAppState) => {
    if (isDeviceParent(device)) {
        return state.deviceState.parentDevices;
      } else if (isDeviceChild(device)) {
        return state.deviceState.childDevices;
      } else {
        return state.deviceState.subchildDevices;
      }
}

export const renameISC =
    (props: {
        device: TreeDevice;
        newName: string;
    }): ThunkAction<
        void, // thunk return type
        TAppState, // state type
        any, // extra argument, (not used)
        { type: 'RENAME_ISC_DEVICE_NAME'; payload: { device: TreeDevice; newName: string } } // action type
    > =>
    (dispatch: TDispatch, getState: TGetState): void => {

        const oldParentDeviceName = props.device.device_name;
        dispatch(deviceNameChanged({ device: props.device, newName: props.newName }));
        const newSiteCode = getParentSiteCode(props.newName);

        if (!checkDeviceNameValid({ ...props.device, device_name: props.newName }) ||
            checkDupes(props.device, props.newName, getState())) {
                
            log('Site code is invalid, will not proceed with renaming descendants.')
            return;
        }
        log('Updating descentant device site codes.');

        function replaceSiteCode(oldName: string, newSiteCode?: string) {
            return newSiteCode ? oldName.replace(/([A-Za-z0-9]{4,6})\-/, newSiteCode + '-') : oldName;
        }

        getState().deviceState.childDevices.forEach(childDevice => {
            if (childDevice.parent_device_name === oldParentDeviceName) {
                const newDeviceName = replaceSiteCode(childDevice.child_device_name, newSiteCode);
                if (newDeviceName !== childDevice.device_name) {
                    console.log(`${childDevice.child_device_name} => ${newDeviceName}`);
                    dispatch(deviceNameChanged({ device: childDevice, newName: newDeviceName, isParentRename: true }));
                }
            }
        });

        getState().deviceState.subchildDevices.forEach(subchildDevice => {
            if (subchildDevice.parent_device_name === oldParentDeviceName) {
                const newDeviceName = replaceSiteCode(subchildDevice.subchild_device_name, newSiteCode);
                if (newDeviceName !== subchildDevice.device_name) {
                    console.log(`${subchildDevice.subchild_device_name} => ${newDeviceName}`);
                    dispatch(deviceNameChanged({ device: subchildDevice, newName: newDeviceName, isParentRename: true }));
                }
            }
        });
    }