import * as React from 'react';
import TopNavigation from '@amzn/awsui-components-react/polaris/top-navigation/1.0-beta';
import { selectUsername, toggleSelectSite, toggleUserPreferences } from 'src/stores/slices/userSlice';
import { useAppDispatch, useAppSelector } from 'src/stores/slices/hooks';
import { envVariables } from 'src/resources/envVariables';
import { CONSTANTS } from 'src/resources/constants';
import { toggleDevMode } from 'src/stores/slices/devSlice';

export const TopNav = (props: {}) => {
    const dispatch = useAppDispatch();
    const username = useAppSelector(selectUsername);
    const i18nStrings = {
        searchIconAriaLabel: 'Search',
        searchDismissIconAriaLabel: 'Close search',
        overflowMenuTriggerText: 'More',
    };

    let title = `FAR`;
    if (envVariables.stage.toLowerCase() != 'prod') {
        title += ` ${envVariables.stage.toUpperCase()}`;
    } else {
        title += ` Device Renaming`;
    }

    return (
        <div className='awsui' id='header' style={{ position: 'sticky', top: 0, zIndex: 1002 }}>
            <TopNavigation
                id={'headerTopNav'}
                i18nStrings={i18nStrings}
                identity={{
                    href: '#',
                    title: title,
                }}
                utilities={[
                    {
                        type: 'button',
                        text: `Select Site(s)`,
                        iconName: 'edit',
                        ariaLabel: 'Select Sites',
                        href: '#/SelectSite',
                        badge: false,
                        disableUtilityCollapse: true,
                        onClick: () => {
                            dispatch(toggleSelectSite());
                        },
                    },
                    {
                        type: 'button',
                        text: `User Preferences`,
                        iconName: 'settings',
                        ariaLabel: 'User Preferences',
                        href: '#/SelectSite',
                        badge: false,
                        disableUtilityCollapse: true,
                        onClick: () => {
                            dispatch(toggleUserPreferences());
                        },
                    },
                    {
                        type: 'button',
                        text: `Guardian Hub`,
                        ariaLabel: 'Guardian Hub',
                        href: CONSTANTS.GUARDIAN_HUB,
                        external: true,
                        badge: false,
                        disableUtilityCollapse: true,
                    },
                    {
                        type: 'menu-dropdown',
                        text: 'Help',
                        items: [
                            {
                                id: 'bug',
                                text: 'Report a Bug',
                                href: CONSTANTS.REPORT_BUG,
                                external: true,
                                externalIconAriaLabel: ' (opens in new tab)',
                            },
                            {
                                id: 'feature',
                                text: 'Feature Requests',
                                href: CONSTANTS.FEATURE_REQUEST,
                                external: true,
                                externalIconAriaLabel: ' (opens in new tab)',
                            },
                            {
                                id: 'guide',
                                text: 'End User Guide',
                                href: CONSTANTS.WIKI_LINK,
                                external: true,
                                externalIconAriaLabel: ' (opens in new tab)',
                            },
                        ],
                    },
                    {
                        type: 'button',
                        text: username,
                        iconName: 'user-profile',
                        onClick: () => {
                            dispatch(toggleDevMode());
                        },
                    },
                ]}
            />
        </div>
    );
};
