import React, { useState } from 'react';
import TopNotification from './TopNotification';
import { Box, Container } from '@amzn/awsui-components-react';
import { useTranslation } from 'react-i18next';
import ExpandableDevice from 'src/components/ExpandableDevice/ExpandableDevice';
import { log } from 'src/utils/helpers';

function Device() {
    const [expanded, setExpanded] = useState(false);
    const [deviceName, setDeviceName] = useState('deviceName');

    const devices = ['device1', 'device2'];
    const textSize = '1.5em';
    const iconSize = '1.5em';

    const children = devices.map((device) => {
        return (
            <ExpandableDevice
                textSize={textSize}
                iconSize={iconSize}
                onDeviceNameChanged={(changeDetails) => {
                    log(`New device name ${changeDetails.newName}`);
                    setDeviceName(changeDetails.newName);
                    return {
                        success: true,
                        error: '',
                    };
                }}
                deviceName={device}
            />
        );
    });

    return (
        <ExpandableDevice
            textSize={textSize}
            iconSize={iconSize}
            expanded={expanded}
            onChangeExpanded={(change) => {
                log('onchangeHandler');
                setExpanded(change.expanded);
            }}
            onDeviceNameChanged={(changeDetails) => {
                log(`New device name ${changeDetails.newName}`);
                setDeviceName(changeDetails.newName);
                return {
                    success: true,
                    error: '',
                };
            }}
            deviceName={deviceName}
            children={children}
        />
    );
}

export default function SIDEFARTestContent() {
    const { t } = useTranslation();

    return (
        <>
            <Box>
                <TopNotification />
                <Box>
                    <Container>
                        <Device />
                    </Container>
                </Box>
            </Box>
        </>
    );
}
