/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const updateUserPrefs = /* GraphQL */ `mutation UpdateUserPrefs(
  $lastConnected: Float
  $lastSites: String
  $lastUpdated: Float!
  $userId: ID!
) {
  updateUserPrefs(
    lastConnected: $lastConnected
    lastSites: $lastSites
    lastUpdated: $lastUpdated
    userId: $userId
  ) {
    eventsTableLocation
    id
    lastConnected
    lastSites
    lastUpdated
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserPrefsMutationVariables,
  APITypes.UpdateUserPrefsMutation
>;
export const renameDevices = /* GraphQL */ `mutation RenameDevices($devices: [DeviceInput]) {
  renameDevices(devices: $devices)
}
` as GeneratedMutation<
  APITypes.RenameDevicesMutationVariables,
  APITypes.RenameDevicesMutation
>;
