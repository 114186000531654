import React, { useEffect, useState } from 'react';
import { AppLayout, Box, Link, Modal } from '@amzn/awsui-components-react';
import { applyDensity, Density } from '@amzn/awsui-global-styles';
import { Spinner } from '@amzn/awsui-components-react/uxdg';
import { log } from 'src/utils/helpers';
import { useAppDispatch, useAppSelector } from 'src/stores/slices/hooks';
import { CONSTANTS } from 'src/resources/constants';
import { setAllowedSites } from 'src/stores/slices/devicesSlice';
import { envVariables } from 'src/resources/envVariables';
import { API, graphqlOperation } from '@aws-amplify/api';
import { getUserSites } from 'src/graphql/queries';
import IsItDown from 'src/components/IsItDown';

applyDensity(Density.Compact);

// Component Basic is a skeleton of the basic App layout using AWS-UI React components.
export default function Splash() {
    const [connectionError, setConnectionError] = useState(<></>);
    const [infoDisplay, setInfoDisplay] = useState(
        <>
            <IsItDown key={'19636'} />
            <div>
                <Spinner size={'large'} />
            </div>
            <h3>Logging you in and checking for site access...</h3>
        </>,
    );
    const username = useAppSelector((state) => state.userState.username);
    const dispatch = useAppDispatch();
    let backoffTimer = 1000;

    useEffect(() => {
        async function getSites(username: string) {
            if (!username || username == '') {
                log('Waiting for user login');
                return false;
            }
            try {
                const sites: any = await API.graphql(graphqlOperation(getUserSites, { userId: username }));
                log('Got sites', false, { sties: sites });
                // const sites: any = { data: { getUserSites: [] } }; Used for testing error messages
                if (sites.data.getUserSites.length == 0) {
                    log(`NO SITES FOR ${username}`, true);
                    setInfoDisplay(
                        <>
                            <IsItDown />
                            <span style={{ color: 'red' }}>
                                <h3>It appears you are not authorized to use this system.</h3>
                                <Link href={CONSTANTS.HELP_LINK} external target={'_blank'}>
                                    Need Assistance?
                                </Link>
                            </span>
                        </>,
                    );
                    backoffTimer < 15000 ? (backoffTimer += 1000) : {};
                    setTimeout(function () {
                        getSites(username);
                    }, backoffTimer);
                    return false;
                } else {
                    dispatch(setAllowedSites(sites.data.getUserSites));
                    //dispatch(getUserNotificationsAction());
                    return true;
                }
            } catch (e) {
                log('Error getting sites', true, { error: e });
            }

            backoffTimer < 15000 ? (backoffTimer += 1000) : {};
            if (backoffTimer > 4000) {
                setConnectionError(
                    <>
                        <IsItDown />
                        <span style={{ color: 'red' }}>
                            <h3>
                                Error getting your assigned sites, retrying every {backoffTimer / 1000}{' '}
                                seconds
                            </h3>
                            <h3>Please check your connection.</h3>
                        </span>
                    </>,
                );
            }
            setTimeout(function () {
                getSites(username);
            }, backoffTimer);

            return false;
        }

        if (Boolean(username)) {
            console.debug(`Username is set to ${username}, trying to get sites`);
            getSites(username)
                .then((getSitesResponse) => {
                    //log('Got user sites', false, getSitesResponse);
                })
                .catch((e) => {
                    log('Error getting sites, will retry', true, { error: e });
                });
        }
        return () => {};
    }, [username]);

    return (
        <>
            <AppLayout
                content={<Content connectionError={connectionError} infoDisplay={infoDisplay} />} // Main content on the page, defined below
                toolsHide={true}
                navigationHide={true}
            />
        </>
    );
}

// Main content area (fill it in with components!)
function Content(props: { connectionError: JSX.Element; infoDisplay: JSX.Element }) {
    let envNote = <></>;
    if (envVariables.stage.toLowerCase() != 'prod') {
        envNote = (
            <Box>
                <b>Environment: {envVariables.stage.toLowerCase()} </b>
            </Box>
        );
    }
    return (
        <div id='container'>
            <Modal
                id={'splashModal'}
                visible={true}
                size='medium'
                header='Guardian: FAR Authenticating'
                footer={envNote}
            >
                <Box textAlign={'center'}>
                    {props.infoDisplay}
                    {props.connectionError}
                    <p />
                </Box>
            </Modal>
        </div>
    );
}
