import React, { useState } from 'react';
import './expandableDevice.scss';
import { Badge, SpaceBetween, Spinner } from '@amzn/awsui-components-react';
import { IconContext } from 'react-icons';
import { AiFillCaretDown, AiFillCaretRight } from 'react-icons/ai';

export declare namespace ExpandableDeviceProps {
    interface ChangeDetail {
        expanded: boolean;
    }
}

export type BadgeColorOptions = 'red' | 'blue' | 'green' | 'grey' | undefined;

export interface ExpandableDeviceProps {
    deviceName: string;
    expanded?: boolean;
    iconSize?: string;
    textSize?: string;
    checked?: boolean;
    className?: string;
    errorText?: string;
    warningText?: string;
    badgeText?: string;
    badgeColor?: BadgeColorOptions;
    children?: React.ReactNode;
    deviceIcon?: React.ReactNode;
    onChangeExpanded?: (props: { expanded: boolean }) => void;
    onDeviceNameChanged?: (props: { newName: string }) => void;
    onCheckChanged?: (props: { checked: boolean }) => void;
    loading?: boolean; // Indicates server action pending
}

function ErrorText(props: { errorText?: string }) {
    if (props.errorText) {
        return <Badge color={'red'}>{props.errorText}</Badge>;
    }
    return <></>;
}
function WarningText(props: { warningText?: string }) {
    if (props.warningText) {
        return <Badge color={'grey'}>{props.warningText}</Badge>;
    }
    return <></>;
}

function LoadingSpinner(props: { loading?: boolean }) {
    if (props.loading) {
        return <Spinner variant={'normal'} size={'normal'} />;
    }
    return <></>;
}

function BadgeMessage(props: { text: string | undefined; color: BadgeColorOptions }) {
    if (props.text != '') return <Badge color={props.color}>{props.text}</Badge>;
    else return <></>;
}

export default function ExpandableDevice(props: ExpandableDeviceProps) {
    const [editing, setEditing] = useState(false);
    const [newDeviceName, setNewDeviceName] = useState(props.deviceName);
    let iconContainer: React.ReactNode;

    function caretClickHandler(clickEvent: React.MouseEvent<HTMLSpanElement>) {
        clickEvent.stopPropagation();
        let newExpanded = !props.expanded;
        if (props.onChangeExpanded) {
            props.onChangeExpanded({ expanded: newExpanded });
        } else {
            props.expanded = newExpanded;
        }
    }

    let icon: React.ReactNode;
    if (props.expanded) {
        // icon = <Icon name={'caret-down-filled'} />;
        icon = <AiFillCaretDown />;
    } else {
        // icon = <Icon name={'caret-right-filled'} />;
        icon = <AiFillCaretRight />;
    }
    if (props.children)
        iconContainer = (
            <span
                key={'iconContainer'}
                onClick={(clickEvent) => {
                    caretClickHandler(clickEvent);
                }}
                onDoubleClick={(clickEvent) => {
                    clickEvent.stopPropagation();
                }}
            >
                <IconContext.Provider key={'icp'} value={{ size: props.iconSize }}>
                    {icon}
                </IconContext.Provider>
            </span>
        );

    const editTextBox = (
        <input
            key={'editTextBox'}
            type={'text'}
            className={'expandableDeviceEditInput'}
            value={newDeviceName}
            inputMode={'text'}
            onChange={(event) => {
                setNewDeviceName(event.target.value);
            }}
            onKeyUp={(keyEvent) => {
                if (keyEvent.nativeEvent.code == 'Enter') {
                    setEditing(false);
                    if (props.onDeviceNameChanged) {
                        props.onDeviceNameChanged({ newName: newDeviceName });
                    }
                } else if (editing && keyEvent.nativeEvent.code == 'Escape') {
                    // Cancel and reset the input
                    setEditing(false);
                    setNewDeviceName(props.deviceName);
                }
            }}
        />
    );

    const textStyle = { fontSize: props.textSize ?? '1.5em', display: 'inline-block' };

    return (
        <div className={props.className ?? ''}>
            <div
                className={`expandableDeviceContainer ${props.expanded ? 'expanded' : ''}`}
                onDoubleClick={(clickEvent) => {
                    if (!props.loading) setEditing(true);
                    setNewDeviceName(props.deviceName);
                }}
                key={'expandableHeader'}
            >
                <SpaceBetween key={'spaceBetween'} size={'xs'} direction={'horizontal'}>
                    {iconContainer}
                    <input
                        type={'checkbox'}
                        className={'expandableDeviceCheckbox'}
                        key={`checkBox`}
                        checked={props.checked ?? false}
                        onChange={(change) => {
                            if (props.onCheckChanged) {
                                props.onCheckChanged({ checked: change.target.checked });
                            }
                            change.stopPropagation();
                        }}
                    />
                    <span key={'deviceIcon'}>{props.deviceIcon}</span>
                    {editing ? (
                        <span style={textStyle} key={'deviceNameEdit'}>
                            {editTextBox}
                        </span>
                    ) : (
                        <>
                            <span style={textStyle} key={'deviceName'}>
                                {props.loading ? newDeviceName : props.deviceName}
                            </span>
                        </>
                    )}
                    <BadgeMessage key={'badgeMessage'} text={props.badgeText} color={props.badgeColor} />
                    <ErrorText key={'errorText'} errorText={props.errorText} />
                    <WarningText key={'warningText'} warningText={props.warningText} />
                    <LoadingSpinner key={'loadingSpinner'} loading={props.loading} />
                </SpaceBetween>
            </div>

            <div
                key={'expandableDeviceContent'}
                className={'expandableDeviceContent'}
                style={{
                    display: `${props.expanded ? 'block' : 'none'}`,
                }}
            >
                {props.children}
            </div>
        </div>
    );
}
