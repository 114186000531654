import React from 'react';
import { useAppDispatch, useAppSelector } from 'src/stores/slices/hooks';
import { Box, Button, Modal, SpaceBetween } from '@amzn/awsui-components-react';
import { TreeDevice } from 'src/types';
import { checkDeviceNameValid, checkDeviceSiteCodeMatchesParent, checkDeviceTypeIsValid, checkParentSiteCodeExists, log } from 'src/utils/helpers';
import { BsCameraVideo, BsQuestionLg } from 'react-icons/bs';
import { VscCircuitBoard, VscTools } from 'react-icons/vsc';
import { FaNetworkWired } from 'react-icons/fa';
import { GiTRexSkull } from 'react-icons/gi';
import { MdOutlineSensors } from 'react-icons/md';
import { RiAlarmWarningLine } from 'react-icons/ri';
import { TiCalculator } from 'react-icons/ti';
import { IconContext } from 'react-icons/lib';
import { LuRadar } from "react-icons/lu";
import { deviceNameChanged, renameISC, setChecked } from 'src/components/actions/thunks';
import ExpandableDevice from 'src/components/ExpandableDevice/ExpandableDevice';
import { setChildExpanded, setParentExpanded, setSubchildExpanded } from 'src/stores/slices/devicesSlice';
import dancingGuy from '../../public/dancing.gif';
import { setISCRenameData, toggleISCRenameModal } from 'src/stores/slices/userSlice';

let iconSize = '1.4em';
let textSize = '1.5em';

function TreeDeviceIcon(props: { device: TreeDevice }) {
    const darkMode = useAppSelector((state) => state.userPrefsState.darkMode);
    let deviceImage: React.ReactNode;
    const device = props.device;

    const alt = device.device_type_name_special
        ? device.device_type_name_special.replace('_', ' ')
        : 'invalid device';

    const normalColor = darkMode ? 'white' : 'black';
    const warnColor = darkMode ? 'red' : 'red';
    const iconStyle = {
        color: normalColor,
        className: 'inline',
        size: iconSize,
    };

    if (device.invalidName) {
        iconStyle.color = warnColor;
    }

    switch (device.device_type_name_special) {
        case 'parent_device':
            deviceImage = (
                <IconContext.Provider value={iconStyle} key={`parent`}>
                    <FaNetworkWired />
                </IconContext.Provider>
            );
            break;
        case 'camera':
            deviceImage = (
                <IconContext.Provider value={iconStyle} key={`cam`}>
                    <BsCameraVideo />
                </IconContext.Provider>
            );
            break;
        case 'card_reader':
            deviceImage = (
                <IconContext.Provider value={iconStyle} key={`cr`}>
                    <TiCalculator />
                </IconContext.Provider>
            );
            break;
        case 'card_reader_rex':
            deviceImage = (
                <IconContext.Provider value={iconStyle} key={`crr`}>
                    <GiTRexSkull />
                </IconContext.Provider>
            );
            break;
        case 'bosch_point':
        case 'account_input':
        case 'card_reader_aux_input_1':
        case 'card_reader_aux_input_2':
        case 'alarm_panel_input':
        case 'intrusion_panel_input':
            deviceImage = (
                <IconContext.Provider value={iconStyle} key={`ipi`}>
                    <MdOutlineSensors />
                </IconContext.Provider>
            );
            break;

        case 'card_reader_door_contact':
            deviceImage = (
                <IconContext.Provider value={iconStyle} key={`crdc`}>
                    <VscTools />
                </IconContext.Provider>
            );
            break;
        case 'card_reader_aux_output_1':
        case 'card_reader_aux_output_2':
        case 'intrusion_panel_output_onboard':
        case 'alarm_panel_output':
        case 'intrusion_panel_output':
            deviceImage = (
                <IconContext.Provider value={iconStyle} key={`ipo`}>
                    <RiAlarmWarningLine />
                </IconContext.Provider>
            );
            break;
        case 'alarm_panel':
            deviceImage = (
                <IconContext.Provider value={iconStyle} key={`ap`}>
                    <VscCircuitBoard />
                </IconContext.Provider>
            );
            break;
        case 'lidar':
            deviceImage = (
                <IconContext.Provider value={iconStyle} key={`lidar`}>
                    <LuRadar />
                </IconContext.Provider>
            );
            break;
        default:
            log(`No icon found for device type: ${alt}: ${device.device_name}`);
            deviceImage = (
                <IconContext.Provider value={iconStyle} key={`q`}>
                    <BsQuestionLg />
                </IconContext.Provider>
            );
            break;
    }

    //ImCross - x

    return <span className='stDeviceImageParent'>{deviceImage}</span>;
}

function getDeviceName(device: TreeDevice): string {
    let deviceName = device.device_name;
    if (!deviceName) {
        if (device.subchild_device_id == 0 && device.child_device_id == 0) {
            deviceName = device.parent_device_name ?? 'No Name for Device';
        } else if (device.subchild_device_id == 0) {
            deviceName = device.child_device_name ?? 'No Name for Device';
        } else {
            deviceName = device.subchild_device_name ?? 'No Name for Device';
        }
    }
    return deviceName;
}

export function SubChild(props: { subchild: TreeDevice, parent: TreeDevice }) {
    const dispatch = useAppDispatch();

    let errorText = '';
    let warningText = '';
    if (props.subchild.errorText && props.subchild.errorText != '') {
        errorText = props.subchild.errorText;
    } else {
        if (!checkDeviceNameValid(props.subchild, props.parent.device_name)) {
            errorText = 'Invalid Device Name';
        } else {
            if (!checkDeviceSiteCodeMatchesParent(props.subchild.device_name, props.parent.device_name)) {
                warningText = 'Site code does not match parent site code. '
            }
            if (!checkDeviceTypeIsValid(props.subchild)) {
                warningText += 'Device type is invalid.'
            }
        }
    }

    return (
        <ExpandableDevice
            key={`sc${props.subchild.deviceKey}`}
            deviceIcon={<TreeDeviceIcon device={props.subchild} />}
            deviceName={getDeviceName(props.subchild)}
            textSize={textSize}
            iconSize={iconSize}
            loading={props.subchild.loading}
            className={`statusTreeItem statusTreeChild2`}
            expanded={props.subchild.expanded}
            checked={props.subchild.checked}
            errorText={errorText}
            warningText={warningText}
            onChangeExpanded={(change) => {
                log('Change detail', false, { change: change });
                dispatch(setSubchildExpanded({ device: props.subchild, expanded: change.expanded }));
            }}
            onCheckChanged={(change) => {
                dispatch(setChecked({ device: props.subchild, checked: change.checked }));
            }}
            onDeviceNameChanged={(change) => {
                dispatch(deviceNameChanged({ device: props.subchild, newName: change.newName }));
            }}
        />
    );
}

export function Child(props: { child: TreeDevice, parent: TreeDevice }) {
    const dispatch = useAppDispatch();
    const subchildDevices = useAppSelector((state) => state.deviceState.subchildDevices);
    const subChildren = subchildDevices.filter((subchild) => {
        return (
            subchild.deviceKey != props.child.deviceKey &&
            props.child.parent_device_id == subchild.parent_device_id &&
            subchild.child_device_id == props.child.child_device_id &&
            subchild.subchild_device_id != 0
        );
    });
    const renderedSubchildren = subChildren.map((subChild) => {
        return (
            <SubChild key={`${subChild.deviceKey}_${subChild.device_name}_subchild`} subchild={subChild} parent={props.parent} />
        );
    });
    let errorText = '';
    let warningText = '';
    if (props.child.errorText && props.child.errorText != '') {
        errorText = props.child.errorText;
    } else {
        if (!checkDeviceNameValid(props.child, props.parent.device_name)) {
            errorText = 'Invalid Device Name';
        } else {
            if (!checkDeviceSiteCodeMatchesParent(props.child.device_name, props.parent.device_name)) {
                warningText = 'Site code does not match parent site code. '
            }
            if (!checkDeviceTypeIsValid(props.child)) {
                warningText += 'Device type is invalid.'
            }
        }
    }

    return (
        <ExpandableDevice
            key={`child${props.child.deviceKey}`}
            children={renderedSubchildren}
            deviceIcon={<TreeDeviceIcon device={props.child} />}
            deviceName={getDeviceName(props.child)}
            textSize={textSize}
            iconSize={iconSize}
            loading={props.child.loading}
            className={`statusTreeItem statusTreeChild1`}
            expanded={props.child.expanded}
            checked={props.child.checked}
            errorText={errorText}
            warningText={warningText}
            onChangeExpanded={(change) => {
                log('Change detail', false, { change: change });
                dispatch(setChildExpanded({ device: props.child, expanded: change.expanded }));
            }}
            onCheckChanged={(change) => {
                dispatch(setChecked({ device: props.child, checked: change.checked }));
            }}
            onDeviceNameChanged={(change) => {
                dispatch(deviceNameChanged({ device: props.child, newName: change.newName }));
            }}
        />
    );
}

export function Isc(props: { device: TreeDevice }) {
    const dispatch = useAppDispatch();
    const childDevices = useAppSelector((state) => state.deviceState.childDevices);
    const allowedSitesFromState = useAppSelector((state) => state.deviceState.allowedSites);
    const children = childDevices.filter((device) => {
        return device.parent_device_id == props.device.parent_device_id;
    });
    const renderedChildren = children.map((child) => {
        return <Child key={`${child.deviceKey}_child`} child={child} parent={props.device} />;
    });

    let errorText = '';
    if (props.device.errorText && props.device.errorText != '') {
        errorText = props.device.errorText;
    } else {
        if (!checkDeviceNameValid(props.device)) {
            errorText = 'Invalid Device Name';
        }
    }

    let warningText = '';
    if (!checkParentSiteCodeExists(props.device, allowedSitesFromState)){
        warningText = 'Site Code Does Not Exist';
    }

    return (
        <ExpandableDevice
            key={`isc${props.device.deviceKey}`}
            children={renderedChildren}
            deviceIcon={<TreeDeviceIcon device={props.device} />}
            deviceName={getDeviceName(props.device)}
            textSize={textSize}
            iconSize={iconSize}
            loading={props.device.loading}
            className={`statusTreeItem statusTreeChild0`}
            expanded={props.device.expanded}
            checked={props.device.checked}
            errorText={errorText}
            warningText={warningText}
            badgeText={props.device.DeviceSource.toLocaleUpperCase()}
            badgeColor={props.device.DeviceSource.toLowerCase() == 'onguard' ? 'blue' : 'green'}
            onChangeExpanded={(change) => {
                dispatch(setParentExpanded({ device: props.device, expanded: change.expanded }));
            }}
            onCheckChanged={(change) => {
                dispatch(setChecked({ device: props.device, checked: change.checked }));
            }}
            onDeviceNameChanged={(change) => {
                dispatch(toggleISCRenameModal());
                dispatch(setISCRenameData({ device: props.device, newName: change.newName }));
            }}
        />
    );
}

export function ISCRenameModal() {
    const visible = useAppSelector((state) => state.userState.showISCRenameModal);
    const actionData = useAppSelector((state) => state.userState.ISCRenameData) as any;
    const dispatch = useAppDispatch();
    return (
        <Modal
            onDismiss={() => dispatch(toggleISCRenameModal())}
            visible={visible}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" onClick={() => dispatch(toggleISCRenameModal())}>Cancel</Button>
                        <Button variant="primary" onClick={() => {
                            dispatch(renameISC(actionData));
                            dispatch(toggleISCRenameModal());
                        }}>Ok</Button>
                    </SpaceBetween>
                </Box>
            }
            header="Site Code Change Confirmation"
        >
            This change will update the site code for all devices on this panel. <br></br>
            Press 'Ok' to continue or 'Cancel' to cancel the change.
        </Modal>
    );
}

export function DeviceTree() {
    const darkMode = useAppSelector((state) => state.userPrefsState.darkMode);
    const parentDevices = useAppSelector((state) => state.deviceState.parentDevices);
    const loadingDevices = useAppSelector((state) => state.deviceState.loadingDevices);

    const renderedIscs = parentDevices.map((isc) => {
        return <Isc key={`${isc.deviceKey}_isc`} device={isc}></Isc>;
    });

    if (!loadingDevices) {
        return <Box>{renderedIscs}</Box>;
    } else if (!loadingDevices && !parentDevices.length) {
        return <h4>No Devices Returned</h4>;
    }
    return (
        <Box textAlign='center' color='inherit' key={'deviceTree'}>
            <SpaceBetween size={'l'}>
                {/* <Spinner size={'large'} /> */}
                <div className={`${darkMode ? 'invert' : ''}`}>
                    <img src={dancingGuy} />
                </div>
                <b>Loading Devices...</b>
            </SpaceBetween>
        </Box>
    );
}
